import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

import { setPersonData } from '../coApplicant/redux/actions'
import {
  setEntityData,
  // setPersonData
} from './redux/actions'

import { setEntityDataPartner } from '../partnerConnect/redux/actions'
import { Spinner } from 'react-bootstrap-v5'
import { AlexiApplicationTypes } from '../../../utils/constants/AlexiApplicationType.enum'
import { AlexiRoutes } from '../../../utils/constants/AlexiRoutes.enum'

const Callback: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const loanApplicationId = localStorage.getItem('loanApplicationId')
  const loanType = localStorage.getItem('loanType')
  const coApplicationId = localStorage.getItem('coApplicationId')
  const partnerId = localStorage.getItem('partnerId')
  const companyUEN = localStorage.getItem('companyUEN')
  const co_applicant = useSelector((state: any) => state.coApplicant.co_applicant)
  const callbackFunction = async () => {
    const singpass = localStorage.getItem('singpass')
    const singPassUrl = process.env.REACT_APP_SINGPASS_URL || 'http://localhost:3002'
    // const singPassUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3002' : 'https://iris-dev.credilinq.ai/api/alexi-singpass';
    const url = new URL(window.location.href)
    if (url.searchParams.get('code') && url.searchParams.get('state')) {
      const code = url.searchParams.get('code')
      const state = url.searchParams.get('state')

      if (singpass === 'myinfobiz') {
        // const entityData = await axios
        //   .post(singPassUrl + '/singpass/myinfobiz/getEntityPersonData', { code, state })
        //   .then((response) => response.data)
        const entityData = await axios
          .post(singPassUrl + '/getentitypersonData', { code, state })
          .then((response) => response.data)
        if (
          loanApplicationId &&
          ['stage', 'sandbox', 'dev'].includes(localStorage.getItem('env') as string)
        ) {
          switch (loanType) {
            case AlexiApplicationTypes.LOAN_APPLICATION:
            case AlexiApplicationTypes.RECURRING_APPLICATION:
              ;(async () => {
                const response = await import(`../../../utils/custom/${companyUEN}/step3.json`)
                const eligibilityData: any = response.default
                if (eligibilityData) {
                  // Dispatch the action to update the loan eligibility in the Redux store
                  dispatch(setEntityData(eligibilityData))
                } else {
                  dispatch(setEntityData(entityData.text))
                }
              })()
              history.replace(`/${AlexiRoutes.LOAN_APPLICATION}/${loanApplicationId}?step=3`)
              break

            case AlexiApplicationTypes.PARTNER_CONNECT_APPLICATION:
              ;(async () => {
                const response = await import(`../../../utils/custom/${companyUEN}/step3.json`)

                const eligibilityData: any = response.default
                if (eligibilityData) {
                  // Dispatch the action to update the loan eligibility in the Redux store
                  dispatch(setEntityDataPartner(eligibilityData))
                } else {
                  dispatch(setEntityDataPartner(entityData.text))
                }
              })()

              history.replace(
                `/${AlexiRoutes.PARTNER_CONNECT}/${partnerId}/${loanApplicationId}?step=3`
              )
              break

            case AlexiApplicationTypes.SG_VENDORS_ONBOARDING:
              ;(async () => {
                const response = await import(`../../../utils/custom/${companyUEN}/step3.json`)

                const eligibilityData: any = response.default
                if (eligibilityData) {
                  // Dispatch the action to update the loan eligibility in the Redux store
                  dispatch(setEntityDataPartner(eligibilityData))
                } else {
                  dispatch(setEntityDataPartner(entityData.text))
                }
              })()

              history.replace(
                `/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerId}/${loanApplicationId}?step=3`
              )
              break

            case AlexiApplicationTypes.KYC_ONBOARDING:
            case AlexiApplicationTypes.TTS:
            case AlexiApplicationTypes.AMZ:
            case AlexiApplicationTypes.LAZ:
            case AlexiApplicationTypes.SOP:
            case AlexiApplicationTypes.DSME:
              ;(async () => {
                const response = await import(`../../../utils/custom/${companyUEN}/step3.json`)

                const eligibilityData: any = response.default
                if (eligibilityData) {
                  // Dispatch the action to update the loan eligibility in the Redux store
                  dispatch(setEntityDataPartner(eligibilityData))
                } else {
                  dispatch(setEntityDataPartner(entityData.text))
                }
              })()

              history.replace(`/${AlexiRoutes.SG_ONBOARDING}/${loanApplicationId}?step=3`)
              break
          }
        }
        if (loanApplicationId) {
          switch (loanType) {
            case AlexiApplicationTypes.LOAN_APPLICATION:
            case AlexiApplicationTypes.RECURRING_APPLICATION:
              dispatch(setEntityData(entityData.text))
              history.replace(`/${AlexiRoutes.LOAN_APPLICATION}/${loanApplicationId}?step=3`)
              break

            case AlexiApplicationTypes.PARTNER_CONNECT_APPLICATION:
              dispatch(setEntityDataPartner(entityData.text))
              history.replace(
                `/${AlexiRoutes.PARTNER_CONNECT}/${partnerId}/${loanApplicationId}?step=3`
              )
              break

            case AlexiApplicationTypes.SG_VENDORS_ONBOARDING:
              dispatch(setEntityDataPartner(entityData.text))
              history.replace(
                `/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerId}/${loanApplicationId}?step=3`
              )
              break

            case AlexiApplicationTypes.KYC_ONBOARDING:
            case AlexiApplicationTypes.TTS:
            case AlexiApplicationTypes.AMZ:
            case AlexiApplicationTypes.LAZ:
            case AlexiApplicationTypes.SOP:
            case AlexiApplicationTypes.DSME:
              dispatch(setEntityDataPartner(entityData.text))
              history.replace(`/${AlexiRoutes.SG_ONBOARDING}/${loanApplicationId}?step=3`)
              break
          }
        }
      }
      // For Netrust Integration
      //   else if (singpass === 'myinfo') {
      //     const session_id = localStorage.getItem('SINGPASS_SESSION_ID')
      //     const personData = await axios
      //       .post(singPassUrl + '/singpass/myinfo/getPersonData', {
      //         code,
      //         session_id: session_id || null,
      //       })
      //       .then((response) => response.data)
      //     dispatch(setPersonData(personData.text))
      //     localStorage.removeItem('SINGPASS_SESSION_ID')
      //     // localStorage.removeItem('singpass');
      //     history.replace(`/co-applicant/${coApplicationId}?subStep=3`)
      //   } else {
      //     if (coApplicationId) {
      //       history.replace(`/co-applicant/${coApplicationId}`)
      //     } else if (loanApplicationId) {
      //       history.replace(`/application/${loanApplicationId}?step=2`)
      //     }
      //   }
      // }

      // Without Netrust Integration
      else if (singpass === 'myinfo') {
        const personData = await axios
          .post(singPassUrl + '/getpersonData', { code, state })
          .then((response) => response.data)

        if (['stage', 'sandbox', 'dev'].includes(localStorage.getItem('env') as string)) {
          ;(async () => {
            try {
              const response = await import(
                `../../../utils/custom/202101103R/${co_applicant?.idNumber}.json`
              )
              const eligibilityData: any = response.default
              if (eligibilityData) {
                console.log('came here inside eligibiltiy')
                dispatch(setPersonData(eligibilityData))
              }
            } catch (error) {
              console.log('came here - import failed')
              dispatch(setPersonData(personData.text))
            }
          })()
        } else {
          dispatch(setPersonData(personData.text))
        }
        // localStorage.removeItem('singpass');
        history.replace(`/co-applicant/${coApplicationId}?subStep=3`)
      } else {
        if (coApplicationId) {
          history.replace(`/co-applicant/${coApplicationId}`)
        } else if (loanApplicationId) {
          history.replace(`/application/${loanApplicationId}?step=2`)
        }
      }
    } else {
      //error-description=Resource Owner did not authorize the request&error=access_denied&state=6214
      if (url.searchParams.get('error') === 'access_denied') {
        if (singpass === 'myinfobiz' && loanApplicationId) {
          switch (loanType) {
            case AlexiApplicationTypes.LOAN_APPLICATION:
            case AlexiApplicationTypes.RECURRING_APPLICATION:
              history.replace(`/${AlexiRoutes.LOAN_APPLICATION}/${loanApplicationId}?step=2`)
              break

            case AlexiApplicationTypes.PARTNER_CONNECT_APPLICATION:
              history.replace(
                `/${AlexiRoutes.PARTNER_CONNECT}/${partnerId}/${loanApplicationId}?step=2`
              )
              break

            case AlexiApplicationTypes.SG_VENDORS_ONBOARDING:
              history.replace(
                `/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerId}/${loanApplicationId}?step=2`
              )
              break

            case AlexiApplicationTypes.KYC_ONBOARDING:
            case AlexiApplicationTypes.TTS:
            case AlexiApplicationTypes.AMZ:
            case AlexiApplicationTypes.LAZ:
            case AlexiApplicationTypes.SOP:
            case AlexiApplicationTypes.DSME:
              history.replace(`/${AlexiRoutes.SG_ONBOARDING}/${loanApplicationId}?step=1.3`)
              break
          }
        } else if (singpass === 'myinfo') {
          localStorage.removeItem('singpass')
          history.replace('/co-applicant/' + coApplicationId)
        } else {
        }
      }
    }
  }

  if (document.readyState === 'complete') {
    callbackFunction()
  } else {
    window.addEventListener('load', callbackFunction)
  }

  return (
    <div id='splash-screen' className='splash-screen'>
      <Spinner
        style={{
          height: '5.35rem',
          width: '5.35rem',
        }}
        animation='grow'
        variant='danger'
      />
    </div>
  )
}

export default Callback
