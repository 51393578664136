/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import CompanyVerification from '../CompanyVerification'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { ServerError, useLazyQuery, useMutation } from '@apollo/client'
import mutations from '../../../../setup/graphql/mutations'
import Alert from '../../../../_metronic/partials/alert'
import { fetchStart, fetchStop } from '../../../../redux/actions/common'
import { setLoanApplication, setLoanStep, setLoanSubStep } from '../redux/actions'
import BankStatementValidator from '../../../../components/BankStatementValidator/BankStatementValidator'
import { generatePerfiosReport } from '../../../../components/BankStatementValidator/utils/perfiosTransaction'
import { CustomLoaderModal } from '../../../../_metronic/helpers/components/CustomLoaderModal'

import { Modal } from 'react-bootstrap-v5'
import { AlexiApplicationTypes } from '../../../../utils/constants/AlexiApplicationType.enum'
import { Stack } from 'react-bootstrap'
import { UploadIcon } from '../../../../svg/UploadIcon'
import { Xicon } from '../../../../svg/Xicon'
import { LeftArowIcon } from '../../../../svg/LeftArowIcon'
import { useHistory } from 'react-router-dom'
import { RightAeroIcon } from '../../../../svg/RightAeroIcon'
import query from '../../../../setup/graphql/query'
import { nanoid } from '@reduxjs/toolkit'
import { LoanCompleteIcon } from '../../../../svg/LoanCompleteIcon'
import { VerifyIcon } from '../../../../svg/VerifyIcon'
import { CheckCircleIcon } from '../../../../svg/CheckCircleIcon'
import FileUploadInfo from '../../../../components/BankStatementValidator/FileUploadInfo/FileUploadInfo'

const Step2: FC = ({
  // prevStep,
  // goToStep,
  getApplication,
  updateApplication,
}: any) => {
  const dispatch = useDispatch()
  const xlsxRef: any = useRef()
  const history: any = useHistory()
  const [openCustomLoaderModal, setOpenCustomLoaderModal] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [openVerification, setOpenVerification] = useState(false)
  const [fileGroup, setFileGroup] = useState<any>([])
  const [validateStmtsNow, setValidateStmtsNow] = useState<boolean>(true)
  const [reuploadWarningModal, setReuploadWarningModal] = useState<boolean>(false)
  const [removeAllUploadedStatements, setRemoveAllUploadedStatements] = useState<boolean>(false)
  const [isBankReportGenerated, setIsBankReportGenerated] = useState<boolean>(false)
  const [uploadedFiles, setUploadedFiles] = useState<any>([])
  const [uploadedCorrectStatements, setUploadedCorrectStatements] = useState(false)
  const [createIncomeDocument, onSuccessCreateIncomeDocument] = useMutation(
    mutations.CREATE_INCOME_DOCUMENTS
  )
  const [deleteIncomeDocument, onSuccessDeleteIncomeDocument] = useMutation(
    mutations.DELETE_INCOME_DOCUMENT
  )

  const loanApplication = useSelector((state: any) => state.loan.loanApplication)
  const loanType = localStorage.getItem('loanType')

  useEffect(() => {
    if (loanApplication?.incomeDocuments) {
      const { incomeDocuments } = loanApplication
      let file = []
      for (const document of incomeDocuments) {
        const _file = {
          id: document.id,
          type: document.type,
          file: document.documentFile,
        }
        file.push(_file)
      }

      const bankStatements = file.filter(({ type }: any) => type === 'bank-statement')
      if (
        bankStatements &&
        bankStatements.length &&
        loanApplication?.bankStatementsReportFileName &&
        loanApplication?.bankStatementsReportFilePath
      ) {
        setIsBankReportGenerated(true)
      }

      setValidateStmtsNow(loanApplication?.perfiosIntegration)
      setFileGroup(file)
    }
  }, [loanApplication, loanApplication?.incomeDocuments])

  useEffect(() => {
    if (removeAllUploadedStatements) {
      handleRemoveAllIncomeDocuments()
    }
  }, [removeAllUploadedStatements])

  useEffect(() => {
    if (onSuccessCreateIncomeDocument.called && !onSuccessCreateIncomeDocument.loading) {
      dispatch(fetchStop())
      if (onSuccessCreateIncomeDocument.data) {
        if (onSuccessCreateIncomeDocument.data) {
          const { createIncomeDocuments } = onSuccessCreateIncomeDocument.data
          let file = [...fileGroup]
          for (const document of createIncomeDocuments) {
            const _file = {
              id: document.id,
              type: document.type,
              file: document.documentFile,
            }
            file.push(_file)
          }

          setFileGroup(file)

          if (loanApplication.applicationCompleteStep < 2) {
            updateApplication({
              variables: {
                data: { applicationCompleteStep: 2 },
                where: { id: loanApplication.id },
              },
            })
          }

          onSuccessCreateIncomeDocument.reset()
        }
      } else {
      }

      if (onSuccessCreateIncomeDocument.error) {
        const { networkError } = onSuccessCreateIncomeDocument.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
      }
    }
  }, [
    onSuccessCreateIncomeDocument.loading,
    onSuccessCreateIncomeDocument.data,
    onSuccessCreateIncomeDocument.error,
    onSuccessCreateIncomeDocument.called,
  ])

  const isValidFile = (event: any, type: string) => {
    const checkType = [...event.target.files].some((file: any) => file.type !== type)
    if (checkType) {
      setShowAlert(true)
      setAlertMsg(`Please upload a ${type === 'application/pdf' ? 'pdf' : 'xlsx'} file`)
      setAlertType('primary')
      return false
    }
    return true
  }

  const handleFileUpload = (event: any, type: string, fileType: string = 'application/pdf') => {
    console.log('event.target.validity.valid', event.target.validity.valid, event, type)
    if (event.target.validity.valid) {
      let files = []
      let uploadDocs = []

      const isValid = isValidFile(event, fileType)

      if (isValid) {
        for (let file of event.target.files) {
          const isExists = fileGroup.some((f: any) => f.file.name === file.name && f.type === type)
          console.log('isExists', isExists)
          if (!isExists) {
            file.filename = file.name
            files.push({ type: type, file })
            uploadDocs.push({
              application: {
                connect: {
                  id: loanApplication.id,
                },
              },
              type: type,
              documentFile: {
                upload: file,
              },
            })

            if (
              loanType &&
              loanType.toLowerCase() === AlexiApplicationTypes.RECURRING_APPLICATION
            ) {
              event.target.value = ''
              if (xlsxRef?.current?.value) {
                xlsxRef.current.value = null
              }
            }
          }
        }

        if (files.length) {
          event.target.value = ''
          createIncomeDocument({
            variables: {
              data: uploadDocs,
            },
          })

          dispatch(fetchStart())
        }
      }
    }
  }

  const handleRemoveAllIncomeDocuments = () => {
    try {
      if (fileGroup && fileGroup?.length) {
        const allIncomeDocs = fileGroup.filter(({ type }: any) => type === 'bank-statement')
        const otherDocs = fileGroup.filter(({ type }: any) => type !== 'bank-statement')
        for (const file of allIncomeDocs) {
          deleteIncomeDocument({
            variables: {
              where: { id: file.id },
            },
          })
        }
        updateApplication({
          variables: {
            data: {
              applicationCompleteStep: 2,
              bankStatementsReportFileName: '',
              bankStatementsReportFilePath: '',
              bankStatementsXlsxReportFileName: '',
              bankStatementsXlsxReportFilePath: '',
            },
            where: { id: loanApplication.id },
          },
        })
        setIsBankReportGenerated(false)
        setFileGroup(otherDocs)
      }
    } catch (error) {
      throw error
    }
  }

  const handleStatementUpload = async (type: string = '') => {
    try {
      let files = []
      let uploadDocs = []

      for (let file of uploadedFiles) {
        const { response, ...restFileData } = file
        file.filename = file.name
        files.push({ type: type, restFileData })
        uploadDocs.push({
          application: {
            connect: {
              id: loanApplication.id,
            },
          },
          type: type,
          documentFile: {
            upload: file,
          },
        })
      }

      // _fileGroup = _fileGroup.concat(files);
      // setFileGroup(_fileGroup);

      return createIncomeDocument({
        variables: {
          data: uploadDocs,
        },
      }).then((response: any) => {
        const { createIncomeDocuments } = response?.data

        return createIncomeDocuments
      })
    } catch (error) {
      throw error
    }
  }

  const submitAll = async () => {
    try {
      if (
        validateStmtsNow &&
        (loanApplication.applicationCompleteStep !== 2 ||
          !loanApplication.bankStatementsReportFilePath ||
          removeAllUploadedStatements)
      ) {
        setOpenCustomLoaderModal(true)
        const report = await retrieveBankReport()
        const incomeDocs: any = await handleStatementUpload('bank-statement')
        updateApplication({
          variables: {
            data: {
              applicationCompleteStep: 2,
              bankStatementsReportFileName: report?.reportFileName,
              bankStatementsReportFilePath: report?.reportFilePath,
              bankStatementsXlsxReportFileName: report?.reportXlsxFileName,
              bankStatementsXlsxReportFilePath: report?.reportXlsxFilePath,
            },
            where: { id: loanApplication.id },
          },
        })
        const applications = {
          ...loanApplication,
          incomeDocuments: [...loanApplication.incomeDocuments, ...incomeDocs],
          applicationCompleteStep: 2,
          bankStatementsReportFileName: report?.reportFileName,
          bankStatementsReportFilePath: report?.reportFilePath,
          bankStatementsXlsxReportFileName: report?.reportXlsxFileName,
          bankStatementsXlsxReportFilePath: report?.reportXlsxFilePath,
        }
        dispatch(setLoanApplication(applications))
        setUploadedFiles([])
        setIsBankReportGenerated(true)

        setOpenCustomLoaderModal(false)
      }
      setOpenVerification(true)
    } catch (error) {
      setOpenCustomLoaderModal(false)
      setShowAlert(true)
      setAlertMsg(
        'Sorry we failed to process your statements at this moment, please try after some time.'
      )
      setAlertType('primary')
    }
  }

  /**
   * Calls generate report api
   * @returns report details uploaded on azure
   */
  const retrieveBankReport = async () => {
    try {
      const report = await generatePerfiosReport(
        loanApplication?.id,
        `${loanApplication?.id}_report`,
        loanApplication?.applicationType
      )
      return report
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  const removeSingleFile = async (id: string) => {
    const _fileGroup = fileGroup.filter((o: any) => o.id !== id)
    setFileGroup(_fileGroup)
    await deleteIncomeDocument({
      variables: {
        where: { id: id },
      },
    })
  }

  const reuploadAccepted = () => {
    setValidateStmtsNow(!validateStmtsNow)
    setReuploadWarningModal(false)
    handleRemoveAllIncomeDocuments()
    setUploadedFiles([])
  }

  function backLink() {
    dispatch(setLoanSubStep(3))
    history.replace(`/application/${loanApplication.id}?step=1.3`)
  }

  return (
    <div className='d-flex flex-column flex-column-fluid'>
      <div className='cardNew'>
        <div className='section-heading'>
          <h2>
            Bank Statements {uploadedCorrectStatements && <CheckCircleIcon color='var(--sucess)' />}
            {validateStmtsNow &&
              loanApplication.applicationCompleteStep === 2 &&
              loanApplication.bankStatementsReportFilePath &&
              fileGroup.length &&
              fileGroup.some(({ type }: any) => type === 'bank-statement') &&
              !removeAllUploadedStatements &&
              !uploadedCorrectStatements && <CheckCircleIcon color='var(--warning)' />}
          </h2>
          <hr className='text-gray-400' />
        </div>
        <div>
          {validateStmtsNow && (
            <div className='card-body'>
              <BankStatementValidator
                refId={loanApplication?.id}
                refType={loanApplication?.applicationType}
                setUploadedCorrectStatements={setUploadedCorrectStatements}
                setUploadedFiles={setUploadedFiles}
                setIsBankReportGenerated={setIsBankReportGenerated}
                isBankReportGenerated={isBankReportGenerated}
                removeAllUploadedStatements={setRemoveAllUploadedStatements}
                validateMonthsTill={6}
              />
            </div>
          )}
          {!validateStmtsNow && (
            <>
              <Stack direction='vertical' style={{ width: '100%', fontSize: '14px' }}>
                <div className='bank-statement-upload mb-3 rounded' style={{ width: '100%' }}>
                  <FileUploadInfo />
                </div>
              </Stack>
              <div
                style={{
                  border: '1px dashed #BBBFC4',
                  padding: '63px 40px',
                  background: 'rgba(26,3,85,0.02)',
                  minHeight: '150px',
                  fontSize: '14px',
                  marginBottom: '15px',
                  borderRadius: '10px',
                  alignItems: 'center',
                }}
              >
                <Stack
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  gap={2}
                >
                  <div className='upload mb-4'>
                    <input
                      id='upload-file-bank'
                      type='file'
                      multiple
                      accept='application/pdf'
                      onChange={(e: any) => handleFileUpload(e, 'bank-statement')}
                    ></input>
                    <label
                      htmlFor='upload-file-bank'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingTop: '10px',
                      }}
                    >
                      <div
                        className='file-uploader'
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <UploadIcon color='var(--primary-color)' />
                        <span>Drag & Drop Bank Statements</span>
                      </div>
                      <div className='file-uploader-sub'>Supported format: PDF Only</div>
                    </label>
                  </div>
                </Stack>
              </div>
              <div className=''>
                <Stack
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(6, 1fr)',
                    gap: '12px',
                    fontSize: '12px',
                    minWidth: '100%',
                  }}
                >
                  {fileGroup && fileGroup.length > 0 && (
                    <>
                      {fileGroup
                        .filter(({ type }: any) => type === 'bank-statement')
                        .map(({ file, type, id }: any, i: number) => {
                          const filename = file.filename.split('.')
                          const name = filename[0].substring(0, 15)
                          const extension = filename[1]

                          return (
                            <div
                              key={i}
                              style={{
                                fontSize: 14,
                                color: '#616B75',
                                background: '#F9FAFA',
                                borderRadius: 4,
                                padding: '8px 16px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                              }}
                              title={file.filename}
                            >
                              <span>{`${name}...${extension}`}</span>
                              <Xicon
                                onClick={() => removeSingleFile(id)}
                                className='cursor-pointer'
                              />
                            </div>
                          )
                        })}
                    </>
                  )}
                </Stack>
              </div>
            </>
          )}
          {uploadedCorrectStatements ? (
            <div className='text-success'>
              Your Bank Statements have been successfully validated
            </div>
          ) : null}
          {validateStmtsNow &&
          loanApplication.applicationCompleteStep === 2 &&
          loanApplication.bankStatementsReportFilePath &&
          fileGroup.length &&
          fileGroup.some(({ type }: any) => type === 'bank-statement') &&
          !removeAllUploadedStatements &&
          !uploadedCorrectStatements ? (
            <div className='text-alert'>
              You have already uploaded your bank statements, now you can either re-upload your bank
              statements or proceed further.
            </div>
          ) : null}
        </div>
      </div>

      {loanType && loanType.toLowerCase() === AlexiApplicationTypes.RECURRING_APPLICATION ? (
        <div className='cardNew'>
          <div className='section-heading'>
            <h2>
              Revenue Details{' '}
              {fileGroup &&
                fileGroup.filter(({ type }: any) => type === 'revenue-financing-statement').length >
                  0 && <CheckCircleIcon color='var(--sucess)' />}
            </h2>
            <hr className='text-gray-400' />
          </div>
          <div className='bank-statement-upload mb-3 rounded p-4 '>
            <ul className='fs-4'>
              <li>
                <span>
                  You can download the sample template:{' '}
                  <a href={toAbsoluteUrl('/media/alexi/downloadtemplate.xlsx')} target='_blank'>
                    Download the template
                  </a>
                </span>
              </li>
              <li>
                <span>
                  The instructions are clearly mentioned in the document on how to complete/fill
                </span>
              </li>
              <li>
                <span>
                  Do not change any headers and request you to fill all information requested{' '}
                </span>
              </li>
              <li>
                <span>
                  {' '}
                  Once you fill in the application please upload the file using Browse Files button
                </span>
              </li>
            </ul>
          </div>

          <div
            style={{
              border: '1px dashed #BBBFC4',
              padding: '63px 40px',
              background: 'rgba(26,3,85,0.02)',
              minHeight: '150px',
              fontSize: '14px',
              marginBottom: '15px',
              borderRadius: '10px',
              alignItems: 'center',
            }}
          >
            <Stack
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
              gap={2} // Increased gap for better spacing
            >
              <div className='upload mb-4'>
                <input
                  id='upload-file-revenue'
                  type='file'
                  multiple
                  accept='.xlsx'
                  onChange={(e: any) =>
                    handleFileUpload(
                      e,
                      'revenue-financing-statement',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    )
                  }
                ></input>
                <label
                  htmlFor='upload-file-revenue'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: '10px',
                  }}
                >
                  <div
                    className='file-uploader'
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <UploadIcon color='var(--primary-color)' />

                    <span>Drag & Drop Revenue Statements</span>
                  </div>
                  <div className='file-uploader-sub'>Supported formats: XLSX, XLS</div>
                </label>
              </div>
            </Stack>
          </div>
          <div className=''>
            <Stack
              style={{
                display: 'grid', // Change to grid layout
                gridTemplateColumns: 'repeat(6, 1fr)', // Create 6 equal columns
                gap: '12px', // Adjusted gap for better spacing
                fontSize: '12px',
                minWidth: '100%',
              }}
            >
              {fileGroup && fileGroup.length > 0 && (
                <>
                  {fileGroup
                    .filter(({ type }: any) => type === 'revenue-financing-statement')
                    .map(({ file, type, id }: any, i: number) => {
                      const filename = file.filename.split('.')
                      const name = filename[0].substring(0, 15)
                      const extension = filename[1]

                      return (
                        <div
                          key={i}
                          style={{
                            fontSize: 14,
                            color: '#616B75',
                            background: '#F9FAFA',
                            borderRadius: 4,
                            padding: '8px 16px',
                            display: 'flex', // Use flex to position items
                            justifyContent: 'space-between', // Space between text and icon
                            alignItems: 'center', // Center items vertically
                            width: '100%', // Set to 100% to fill the grid cell
                          }}
                          title={file.filename}
                        >
                          <span>{`${name}...${extension}`}</span>
                          <Xicon onClick={() => removeSingleFile(id)} className='cursor-pointer' />
                        </div>
                      )
                    })}
                </>
              )}
            </Stack>
          </div>
        </div>
      ) : null}

      {loanApplication.amountRequested > 250000 && (
        <div className='cardNew'>
          <div className='section-heading'>
            <h2>
              Financial Statements{' '}
              {fileGroup &&
                fileGroup.filter(({ type }: any) => type === 'financial-statement').length > 0 && (
                  <CheckCircleIcon color='var(--sucess)' />
                )}
            </h2>
            <hr className='text-gray-400' />
          </div>
          <div className='bank-statement-upload mb-3 rounded p-4 '>
            <ul className='fs-4'>
              <li>2-years audited financial statements</li>
              <li>You can upload native pdf as well as scanned document</li>
            </ul>
          </div>

          <div
            style={{
              border: '1px dashed #BBBFC4',
              padding: '63px 40px',
              background: 'rgba(26,3,85,0.02)',
              minHeight: '150px',
              fontSize: '14px',
              marginBottom: '15px',
              borderRadius: '10px',
              alignItems: 'center',
            }}
          >
            <Stack
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
              gap={2} // Increased gap for better spacing
            >
              <div className='upload mb-4'>
                <input
                  id='upload-file-finance'
                  type='file'
                  multiple
                  accept='application/pdf'
                  onChange={(e: any) => handleFileUpload(e, 'financial-statement')}
                ></input>
                <label
                  htmlFor='upload-file-finance'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: '10px',
                  }}
                >
                  <div
                    className='file-uploader'
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <UploadIcon color='var(--primary-color)' />

                    <span>Drag & Drop Financial Statements</span>
                  </div>
                  <div className='file-uploader-sub'>Supported format: PDF Only</div>
                </label>
              </div>
            </Stack>
          </div>
          <div className=''>
            <Stack
              style={{
                display: 'grid', // Change to grid layout
                gridTemplateColumns: 'repeat(6, 1fr)', // Create 6 equal columns
                gap: '12px', // Adjusted gap for better spacing
                fontSize: '12px',
                minWidth: '100%',
              }}
            >
              {fileGroup && fileGroup.length > 0 && (
                <>
                  {fileGroup
                    .filter(({ type }: any) => type === 'financial-statement')
                    .map(({ file, type, id }: any, i: number) => {
                      const filename = file.filename.split('.')
                      const name = filename[0].substring(0, 15)
                      const extension = filename[1]

                      return (
                        <div
                          key={i}
                          style={{
                            fontSize: 14,
                            color: '#616B75',
                            background: '#F9FAFA',
                            borderRadius: 4,
                            padding: '8px 16px',
                            display: 'flex', // Use flex to position items
                            justifyContent: 'space-between', // Space between text and icon
                            alignItems: 'center', // Center items vertically
                            width: '100%', // Set to 100% to fill the grid cell
                          }}
                          title={file.filename}
                        >
                          <span>{`${name}...${extension}`}</span>
                          <Xicon onClick={() => removeSingleFile(id)} className='cursor-pointer' />
                        </div>
                      )
                    })}
                </>
              )}
            </Stack>
          </div>
        </div>
      )}

      {/* commented gst for now  */}
      {/* <div className='cardNew'>
        <div className='section-heading'>
          <h2>
            GST Statements{' '}
            {fileGroup && fileGroup.length > 0 && <CheckCircleIcon color='var(--sucess)' />}
          </h2>
          <hr className='text-gray-400' />
        </div>
        <div className='bank-statement-upload mb-4 rounded p-4 fs-4'>
          PDF (not scanned copies) of the last 12 months / 4 quarters (GST) statements (can be
          downloaded from IRAS website).
        </div>
        <div
          style={{
            border: '1px dashed #BBBFC4',
            padding: '63px 40px',
            background: 'rgba(26,3,85,0.02)',
            minHeight: '150px',
            fontSize: '14px',
            marginBottom: '15px',
            borderRadius: '10px',
            alignItems: 'center',
          }}
        >
          <Stack
            style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
            gap={2} // Increased gap for better spacing
          >
            <div className='upload  mb-4'>
              <input
                id='upload-file-gst'
                type='file'
                multiple
                accept='application/pdf'
                onChange={(e: any) => {
                  handleFileUpload(e, 'gst-statement')
                }}
              ></input>
              <label
                htmlFor='upload-file-gst'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingTop: '10px',
                }}
              >
                <div
                  className='file-uploader'
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <UploadIcon color='var(--primary-color)' />

                  <span>Drag & Drop GST Statements</span>
                </div>
                <div className='file-uploader-sub'>Supported format: PDF Only</div>
              </label>
            </div>
          </Stack>
        </div>

        <div className=''>
          <Stack
            style={{
              display: 'grid', // Change to grid layout
              gridTemplateColumns: 'repeat(6, 1fr)', // Create 6 equal columns
              gap: '12px', // Adjusted gap for better spacing
              fontSize: '12px',
              minWidth: '100%',
            }}
          >
            {fileGroup && fileGroup.length > 0 && (
              <>
                {fileGroup
                  .filter(({ type }: any) => type === 'gst-statement')
                  .map(({ file, type, id }: any, i: number) => {
                    const filename = file.filename.split('.')
                    const name = filename[0].substring(0, 15)
                    const extension = filename[1]

                    return (
                      <div
                        key={i}
                        style={{
                          fontSize: 14,
                          color: '#616B75',
                          background: '#F9FAFA',
                          borderRadius: 4,
                          padding: '8px 16px',
                          display: 'flex', // Use flex to position items
                          justifyContent: 'space-between', // Space between text and icon
                          alignItems: 'center', // Center items vertically
                          width: '100%', // Set to 100% to fill the grid cell
                        }}
                        title={file.filename}
                      >
                        <span>{`${name}...${extension}`}</span>
                        <Xicon onClick={() => removeSingleFile(id)} className='cursor-pointer' />
                      </div>
                    )
                  })}
              </>
            )}
          </Stack>
        </div>
      </div> */}

      <div className='cardNew stickeyFooter'>
        <div className='d-flex justify-content-between'>
          <button className='button button-outline-back' onClick={backLink}>
            <span className='icon-text'>
              <LeftArowIcon color='currentColor' />
              Back
            </span>
          </button>
          <button
            className='button button-primary'
            // disabled={fileGroup.length < 3}
            // disabled={!fileGroup.some(({type}: any) => type === 'bank-statement')}
            style={{
              background:
                (validateStmtsNow && !isBankReportGenerated && !uploadedCorrectStatements) ||
                (!validateStmtsNow && !fileGroup.some(({ type }: any) => type === 'bank-statement'))
                  ? 'grey'
                  : 'var(--secondary-color)',
            }}
            disabled={
              validateStmtsNow
                ? !isBankReportGenerated && !uploadedCorrectStatements
                : !fileGroup.some(({ type }: any) => type === 'bank-statement')
            }
            onClick={() => submitAll()}
          >
            Save and Continue
            <RightAeroIcon color='currentColor' />
          </button>
        </div>
      </div>

      {openVerification && (
        <CompanyVerification
          show={openVerification}
          handleClose={() => setOpenVerification(false)}
          // goToStep={goToStep}
        />
      )}

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
      <CustomLoaderModal
        openCustomLoaderModal={openCustomLoaderModal}
        setOpenCustomLoaderModal={setOpenCustomLoaderModal}
        message='Please wait while we process your statements, it might take a while.'
      />
      <Modal
        className='bg-transparent'
        id='kt_mega_menu_modal'
        data-backdrop='static'
        aria-hidden='true'
        role='dialog'
        tabIndex='-1'
        contentClassName='shadow-none'
        show={reuploadWarningModal}
      >
        <div className='modal-content'>
          <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
            <div>
              <h3 className='fw-bold m-0'>
                Bank Statements Validation Mode
                <div
                  className='btn btn-icon btn-sm btn-light-primary ms-2'
                  data-bs-dismiss='modal'
                  style={{ float: 'right' }}
                  onClick={() => setReuploadWarningModal(false)}
                >
                  <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
              </h3>
              <div className='mt-2'>
                Are you sure you want to{' '}
                <b>
                  {!validateStmtsNow
                    ? 'Validate Bank-Statements'
                    : 'Skip Bank-Statements Validation'}
                </b>
                ? This will remove all of your previously uploaded statements.
              </div>
            </div>
          </div>
          <div className='d-flex flex-center mt-5 mb-5'>
            <button className='btn btn-lg btn-primary me-3' onClick={reuploadAccepted}>
              I understand
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export { Step2 }
