import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useMutation, useLazyQuery } from '@apollo/client'
import PhoneInput from 'react-phone-number-input'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { fetchStart, fetchStop, fetchStartWithForward } from '../../../../redux/actions/common'
import mutations from '../../../../setup/graphql/mutations'
import query from '../../../../setup/graphql/query'
import Alert from '../../../../_metronic/partials/alert'
import {
  setShareholders,
  setGuarantor,
  setDecisionMaker,
  setPartnerStep,
  setSingPassInfo,
  setPartnerApplication,
  setDirector,
} from '../redux/actions'
import { on } from 'events'
import { LeftArowIcon } from '../../../../svg/LeftArowIcon'
import { RightAeroIcon } from '../../../../svg/RightAeroIcon'

const errorMsg = {
  required: 'Required',
}

const Step3Sub1: FC<any> = ({ updateApplication }) => {
  const dispatch = useDispatch()
  const history: any = useHistory()

  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [guarantorList, setGuarantorList] = useState<any>([])
  const [decisionMakerList, setDecisionMakerList] = useState<any>([])
  const [directorList, setDirectorList] = useState<any>([])
  // const [information, setInformation] = useState<any>(null);
  const [shareholderLists, setShareholderLists] = useState<any[]>([])

  const [getShareholders, onSuccessGetShareholders] = useLazyQuery(query.GET_SHAREHOLDERS)
  const [createExternalDataStore, onSuccessCreateExternalDataStore] = useMutation(
    mutations.CREATE_EXTERNAL_DATA_STORE
  )
  const [updateShareholderKycStatus, onSuccessUpdateShareholderKycStatus] = useMutation(
    mutations.UPDATE_SHAREHOLDER_KYC_STATUS
  )

  const companyVerificationMethod = useSelector(
    (state: any) => state.partnerConnect.companyVerificationMethod
  )
  const { person, entity }: any = useSelector((state: any) => state.partnerConnect.entityPerson)
  const loanApplication = useSelector((state: any) => state.partnerConnect.partnerApplication)
  const shareholders = useSelector((state: any) => state.partnerConnect.shareholders)
  const partnerDetails = useSelector((state: any) => state.partnerConnect.partner)

  useEffect(() => {
    if (
      onSuccessUpdateShareholderKycStatus.called &&
      !onSuccessUpdateShareholderKycStatus.loading
    ) {
      dispatch(fetchStop())
      if (onSuccessUpdateShareholderKycStatus.data) {
        if (onSuccessUpdateShareholderKycStatus.data) {
          dispatch(setPartnerStep(4))
          dispatch(fetchStop())
          const { updateShareholderKycStatuses } = onSuccessUpdateShareholderKycStatus.data
          const id = updateShareholderKycStatuses[0].id
          const url = `${window.location.origin}/co-applicant/${id}`
          history.push(`/partnerconnect/${partnerDetails?.id}/${loanApplication.id}?step=5`)
        }
      } else {
      }

      if (onSuccessUpdateShareholderKycStatus.error) {
        setShowAlert(true)
        setAlertMsg('error')
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [
    onSuccessUpdateShareholderKycStatus.data,
    onSuccessUpdateShareholderKycStatus.loading,
    onSuccessUpdateShareholderKycStatus.error,
  ])

  useEffect(() => {
    if (loanApplication.shareholderKycStatus?.length > 0) {
      const _shareholders = [...loanApplication.shareholderKycStatus]
        .sort(({ allocation: a }: any, { allocation: b }: any) => b - a)
        .filter((o: any) => o.shareholderType === '1')
      var multipleGurantors = _shareholders
        .filter((o: any) => o.isGuarantor === true)
        .map((data: any) => data.id)
      setGuarantorList(multipleGurantors)
      setDecisionMakerList(
        _shareholders.filter((e: any) => e.isDecisionMaker).map((e: any) => e.id)
      )
      setDirectorList(_shareholders.filter((e: any) => e.isDirector).map((e: any) => e.id))
      var shareholders = []
      if (
        _shareholders.some(
          (e: any) =>
            (e.isGurantor || e.isDirector || e.isDecisionMaker) &&
            !e.corppasEmail &&
            !e.corppasMobileNumber
        )
      ) {
        let count = 0
        shareholders = _shareholders.reduce((a: any, b: any) => {
          if (b.isDirector) count++
          if (count > 2 && b.isDirector) {
            return [...a, { ...b, isDirector: false }]
          }
          return [...a, b]
        }, [])
      } else {
        shareholders = _shareholders
      }
      setShareholderLists(shareholders)
      dispatch(setShareholders(shareholders))
    } else {
      dispatch(fetchStart())
      getShareholders({
        variables: {
          uen: loanApplication.companyUEN || '',
          app: loanApplication.id || '',
        },
      })
    }
  }, [loanApplication?.shareholderKycStatus])

  useEffect(() => {
    if (onSuccessGetShareholders.called && !onSuccessGetShareholders.loading) {
      dispatch(fetchStop())
      if (onSuccessGetShareholders.data) {
        const _shareholders = onSuccessGetShareholders.data.unwrapShareholders
          .filter((o: any) => o.shareholderType === '1')
          .sort(({ allocation: a }: any, { allocation: b }: any) => {
            return parseFloat(b) - parseFloat(a)
          })
        var multipleGurantors = _shareholders
          .filter((o: any) => o.isGuarantor === true)
          .map((data: any) => data.id)
        setGuarantorList(multipleGurantors)
        setDirectorList(_shareholders.filter((e: any) => e.isDirector).map((e: any) => e.id))

        setDecisionMakerList(
          _shareholders.filter((e: any) => e.isDecisionMaker).map((e: any) => e.id)
        )
        var shareholders = []
        if (
          _shareholders.some(
            (e: any) =>
              (e.isGurantor || e.isDirector || e.isDecisionMaker) &&
              !e.corppasEmail &&
              !e.corppasMobileNumber
          )
        ) {
          let count = 0
          shareholders = _shareholders.reduce((a: any, b: any) => {
            if (b.isDirector) count++
            if (count > 2 && b.isDirector) {
              return [...a, { ...b, isDirector: false }]
            }
            return [...a, b]
          }, [])
        } else {
          shareholders = _shareholders
        }
        setShareholderLists(shareholders)
        dispatch(fetchStop())
        dispatch(setShareholders(shareholders))
        // onSuccessGetShareholders.reset();
      } else {
      }

      if (onSuccessGetShareholders.error) {
        setShowAlert(true)
        setAlertMsg('error')
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [onSuccessGetShareholders.loading])

  const initialValues: any = {
    shareholders: shareholderLists || [],
  }

  Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a: any) => a) {
    return this.test('unique', message, function (list: any) {
      return list.length === new Set(list.map(mapper)).size
    })
  })

  const validationSchema = Yup.object().shape({
    shareholders: Yup.array().of(
      Yup.object().shape({
        isDecisionMaker: Yup.boolean(),
        isGuarantor: Yup.boolean(),
        corppasEmail: Yup.string()
          .email('Enter Valid Email')
          .when('isGuarantor', {
            is: (isGuarantor: boolean) => isGuarantor === true,
            then: Yup.string().required('Email is required'),
          })
          .when('isDecisionMaker', {
            is: (isDecisionMaker: boolean) => isDecisionMaker === true,
            then: Yup.string().required('Email is required'),
          })
          .when('isDirector', {
            is: (isDirector: boolean) => isDirector === true,
            then: Yup.string().required('Email is required'),
          }),
        corppasMobileNumber: Yup.string()
          .when('isGuarantor', {
            is: (isGuarantor: boolean) => isGuarantor === true,
            then: Yup.string()
              .required('Mobile number is required')
              .test(
                'singaporemobileValidation',
                'Only singapore number supported',
                (value: any) => {
                  return /\+65\d{8}/g.test(value)
                }
              ),
            /* .test('mobileValidation', 'Invalid Mobile number', (value: any) => {
              if (/^\+65(8|9)\d{7}$/g.test(value) || /^\+977\d{10}$/g.test(value)) {
                return true
              }
              return false
            }) */
          })
          .when('isDecisionMaker', {
            is: (isDecisionMaker: boolean) => isDecisionMaker === true,
            then: Yup.string()
              .required('Mobile number is required')
              .test(
                'singaporemobileValidation',
                'Only singapore number supported',
                (value: any) => {
                  return /\+65\d{8}/g.test(value)
                }
              ),
            /* .test('mobileValidation', 'Invalid Mobile number', (value: any) => {
              if (/^\+65(8|9)\d{7}$/g.test(value) || /^\+977\d{10}$/g.test(value)) {
                return true
              }
              return false
            }) */
          })
          .when('isDirector', {
            is: (isDirector: boolean) => isDirector === true,
            then: Yup.string().required('Mobile number is required'),
          }),
      })
    ),
  })

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any, { setStatus, setSubmitting }: any) => {
      const { shareholders } = values

      dispatch(fetchStartWithForward())
      let gurantors = []
      const _decisionMaker = shareholders.filter((o: any) => o.isDecisionMaker)[0]

      if (_decisionMaker) {
        const _gurantors = shareholders.filter((o: any) => o.isGuarantor)
        const _directors = shareholders.filter((o: any) => o.isDirector)
        const list = shareholders.filter(
          (o: any) => o.isGuarantor || o.isDirector || o.isDecisionMaker
        )
        var phoneNumberList = list.map((x: any) => x.corppasMobileNumber)
        var emailList = list.map((x: any) => x.corppasEmail)
        if (_gurantors.length == 0) {
          dispatch(fetchStop())
          setShowAlert(true)
          setAlertMsg('Please select at least one Guarantor!')
          setAlertType('primary')
          return
        } else if (
          phoneNumberList.length !== Array.from(new Set(phoneNumberList)).length ||
          emailList.length !== Array.from(new Set(emailList)).length
        ) {
          dispatch(fetchStop())
          setShowAlert(true)
          setAlertMsg('Mobile numbers and email addresses for shareholders must be unique!')
          setAlertType('primary')
          return
        } else if (_directors.length < Math.min(directorList.length, 2)) {
          dispatch(fetchStop())
          setShowAlert(true)
          setAlertMsg(
            `There must be at least ${Math.min(
              Math.max(directorList.length, 1),
              2
            )} Directors who needs to fill eKYC form!`
          )
          setAlertType('primary')
          return
        } else if (_directors.length > 2) {
          dispatch(fetchStop())
          setShowAlert(true)
          setAlertMsg(`Only 2 directors can be selected!`)
          setAlertType('primary')
          return
        }
        const shareholdersToBeUpdated = shareholders.filter(
          (o: any) =>
            guarantorList.includes(o.id) || o.isCompanyDirector || _decisionMaker.id == o.id
        )
        gurantors = shareholdersToBeUpdated.reduce((acc: any, curr: any) => {
          acc.push({
            data: {
              isDecisionMaker: curr.isDecisionMaker,
              isDirector: curr.isDirector,
              isGuarantor: curr.isGuarantor,
              corppasEmail: curr.corppasEmail,
              corppasMobileNumber: curr.corppasMobileNumber,
            },
            where: { id: curr.id },
          })
          return acc
        }, [])
        // if (!gurantors.some((o: any) => o.data.isDecisionMaker)) {
        //   gurantors.push({
        //     "data": {
        //       "isDecisionMaker": true,
        //       "corppasEmail": _decisionMaker.corppasEmail,
        //       "corppasMobileNumber": _decisionMaker.corppasMobileNumber,
        //     },
        //     "where": { "id": _decisionMaker.id }
        //   })
        // }
        const _loanApplication = { ...loanApplication, shareholderKycStatus: shareholders }
        dispatch(setPartnerApplication(_loanApplication))

        dispatch(setGuarantor(_gurantors))
        dispatch(setDecisionMaker(_decisionMaker))
        dispatch(setDirector(_directors))

        updateShareholderKycStatus({
          variables: {
            data: gurantors,
          },
        })

        if (loanApplication.applicationCompleteStep < 4) {
          updateApplication({
            variables: {
              data: { applicationCompleteStep: 4 },
              where: { id: loanApplication.id },
            },
          })
        }

        // goToStep(4);
        setSubmitting(false)
      } else {
        dispatch(fetchStop())
        setShowAlert(true)
        setAlertMsg('Please Select a Decision Maker')
        setAlertType('warning')
      }
    },
  })

  function backLink() {
    dispatch(setPartnerStep(3))
    history.replace(`/partnerconnect/${partnerDetails?.id}/${loanApplication.id}?step=3`)
  }

  // Note: because of this scrollbar is scrolled constantly at top
  // useEffect(() => {
  //   if (formik.errors.hasOwnProperty('shareholders') && formik.errors.shareholders.length > 0) {
  //     window.scrollTo(0, 0)
  //   }
  // }, [formik])
  return (
    <div className='d-flex flex-column flex-column-fluid'>
      <form onSubmit={formik.handleSubmit}>
        <div className='cardNew'>
          <div className='section-heading'>
            <h2>Shareholders</h2>
            <hr className='text-gray-400' />
            <div className='table-shareholder-wrapper'>
              <table className='table table-bordered text-align-center w-100 px-2'>
                <thead className='table-header px-2'>
                  <tr className='text-dark'>
                    <th className='min-w-210px ms-2 ps-2 detailsCell'>Details</th>
                    <th className='min-w-130px'>Allocation</th>
                    <th className='min-w-130px'>
                      Decision Maker
                      {!formik.values.shareholders.some((o: any) => o.isDecisionMaker) && (
                        <div className='text-danger mt-2'>Decision Maker is required </div>
                      )}
                    </th>
                    <th className='min-w-lg-130px min-w-10px'>Guarantor</th>
                    <th className='min-w-130px'>Director</th>
                    <th className='min-w-210px'>Email</th>
                    <th className='min-w-210px'>Mobile</th>
                  </tr>
                </thead>
                <tbody className='table-bordered-bottom'>
                  {formik.values.shareholders.length > 0 &&
                    formik.values.shareholders.map((o: any, i: number) => {
                      return (
                        <tr key={i}>
                          <td className='ms-2'>
                            <div>
                              <p className='text-dark fs-5 mb-2 mt-0'>
                                <b>ID:</b> {o.idNumber}
                              </p>
                              <p className='text-dark fs-5 mb-2 mt-0'>
                                <b>Name:</b> {o.personName}
                              </p>
                              <p className='text-dark fs-5 mb-2 mt-0'>
                                <b>Nationality:</b> {o.nationality}
                              </p>
                            </div>
                          </td>
                          <td>{o.allocation.toFixed(2)}%</td>
                          <td>
                            <input
                              name={`shareholders[${i}].isDecisionMaker`}
                              type='checkbox'
                              className='form-check-input custom-checkbox'
                              checked={o.isDecisionMaker}
                              onChange={(e: any) => {
                                for (const [
                                  index,
                                  shareholder,
                                ] of formik.values.shareholders.entries()) {
                                  if (shareholder.isDecisionMaker) {
                                    formik.setFieldValue(
                                      `shareholders[${index}].isDecisionMaker`,
                                      false
                                    )
                                  }

                                  if (
                                    shareholder.isDecisionMaker &&
                                    !shareholder.isGuarantor &&
                                    !shareholder.isDirector
                                  ) {
                                    formik.setFieldValue(`shareholders[${index}].corppasEmail`, '')
                                    formik.setFieldValue(
                                      `shareholders[${index}].corppasMobileNumber`,
                                      ''
                                    )
                                  }
                                }

                                formik.setFieldValue(
                                  `shareholders[${i}].isDecisionMaker`,
                                  e.target.checked
                                )
                              }}
                              // disabled={
                              //   formik.values.shareholders.some((o: any) => o.isDecisionMaker) &&
                              //   formik.values.shareholders.find((o: any) => o.isDecisionMaker).idNumber !== o.idNumber
                              // }
                            />
                          </td>
                          <td>
                            {guarantorList.includes(o.id) && (
                              <input
                                name={`shareholders[${i}].isGuarantor`}
                                type='checkbox'
                                className='form-check-input custom-checkbox'
                                defaultChecked={o.isGuarantor}
                                // value={formik.values.shareholders[i].isGuarantor}
                                onChange={(e: any) => {
                                  formik.setFieldValue(
                                    `shareholders[${i}].isGuarantor`,
                                    e.target.checked
                                  )
                                  if (
                                    !e.target.checked &&
                                    !formik.values.shareholders[i].isDirector &&
                                    !formik.values.shareholders[i].isDecisionMaker
                                  ) {
                                    formik.setFieldValue(`shareholders[${i}].corppasEmail`, '')
                                    formik.setFieldValue(
                                      `shareholders[${i}].corppasMobileNumber`,
                                      ''
                                    )
                                  }
                                }}
                                disabled={true}
                                readOnly
                              />
                            )}
                          </td>
                          <td className='me-3'>
                            {o.isCompanyDirector && (
                              <input
                                name={`shareholders[${i}].isDirector`}
                                type='checkbox'
                                className='form-check-input custom-checkbox'
                                defaultChecked={o.isDirector}
                                // value={formik.values.shareholders[i].isGuarantor}
                                onChange={(e: any) => {
                                  formik.setFieldValue(
                                    `shareholders[${i}].isDirector`,
                                    e.target.checked
                                  )
                                  if (
                                    !e.target.checked &&
                                    !formik.values.shareholders[i].isGuarantor &&
                                    !formik.values.shareholders[i].isDecisionMaker
                                  ) {
                                    formik.setFieldValue(`shareholders[${i}].corppasEmail`, '')
                                    formik.setFieldValue(
                                      `shareholders[${i}].corppasMobileNumber`,
                                      ''
                                    )
                                  }
                                }}
                                disabled={
                                  shareholders.filter(
                                    (shareholder: any) => shareholder.isCompanyDirector
                                  ).length < 3
                                }
                                readOnly
                              />
                            )}
                          </td>
                          <td className='me-3'>
                            {(o.isGuarantor || o.isDecisionMaker || o.isDirector) && (
                              <>
                                <input
                                  name={`shareholders[${i}].corppasEmail`}
                                  type='text'
                                  placeholder='Email'
                                  className='form-control form-control-solid mb-5 '
                                  value={formik.values.shareholders[i].corppasEmail}
                                  onChange={(e: any) =>
                                    formik.setFieldValue(
                                      `shareholders[${i}].corppasEmail`,
                                      e.target.value
                                    )
                                  }
                                />
                                {Object.keys(formik.touched).includes('shareholders') &&
                                  Object.keys(formik.errors).includes('shareholders') && (
                                    <>
                                      {(formik.touched.shareholders as any)[i] &&
                                        (formik.errors.shareholders as any)[i] &&
                                        (formik.touched.shareholders as any)[i].corppasEmail &&
                                        (formik.errors.shareholders as any)[i].corppasEmail && (
                                          <div className='text-danger mt-2'>
                                            {(formik.errors.shareholders as any)[i].corppasEmail}
                                          </div>
                                        )}
                                    </>
                                  )}
                              </>
                            )}
                          </td>
                          <td>
                            {(o.isGuarantor || o.isDecisionMaker || o.isDirector) && (
                              <>
                                <PhoneInput
                                  name={`shareholders[${i}].corppasMobileNumber`}
                                  international
                                  defaultCountry='SG'
                                  // countryCallingCodeEditable={false}
                                  value={formik.values.shareholders[i].corppasMobileNumber}
                                  onChange={(value) =>
                                    formik.setFieldValue(
                                      `shareholders[${i}].corppasMobileNumber`,
                                      value
                                    )
                                  }
                                  placeholder='Enter phone number'
                                  className='int-phone-input me-2'
                                />
                                {Object.keys(formik.touched).includes('shareholders') &&
                                  Object.keys(formik.errors).includes('shareholders') && (
                                    <>
                                      {(formik.touched.shareholders as any)[i] &&
                                        (formik.errors.shareholders as any)[i] &&
                                        (formik.touched.shareholders as any)[i]
                                          .corppasMobileNumber &&
                                        (formik.errors.shareholders as any)[i]
                                          .corppasMobileNumber && (
                                          <div className='text-danger mt-2'>
                                            {
                                              (formik.errors.shareholders as any)[i]
                                                .corppasMobileNumber
                                            }
                                          </div>
                                        )}
                                    </>
                                  )}
                              </>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='cardNew stickeyFooter'>
          <div className='d-flex justify-content-between'>
            <button className='button button-outline-back' onClick={backLink}>
              <span className='icon-text'>
                <LeftArowIcon color='currentColor' fontSize={12} />
                Back
              </span>
            </button>
            <button type='submit' className='button button-primary'>
              Save and Continue <RightAeroIcon color='currentColor' />
            </button>
          </div>
        </div>
      </form>

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
    </div>
  )
}

export { Step3Sub1 }
