export enum AlexiApplicationTypes {
  LOAN_APPLICATION = '',
  RECURRING_APPLICATION = 'recurring',
  PARTNER_CONNECT_APPLICATION = 'partnerConnectApplication',
  KYC_ONBOARDING = 'kyc-onboarding',
  SG_VENDORS_ONBOARDING = 'sg-vendors-onboarding',
  AMZ = 'AMZ',
  TTS = 'TTS',
  LAZ = 'LAZ',
  SOP = 'SOP',
  DSME = 'DSME',
}

export enum AlexiApplicationQueryTypes {
  PLD = 'PLD',
  PLC = 'PLC',
  RRF = 'RRF',
  SGV = 'SGV',
  DSME = 'DSME',
}
