/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import CompanyVerification from '../CompanyVerification'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { ServerError, useMutation } from '@apollo/client'
import mutations from '../../../../setup/graphql/mutations'
import Alert from '../../../../_metronic/partials/alert'
import { fetchStart, fetchStop } from '../../../../redux/actions/common'
import BankStatementValidator from '../../../../components/BankStatementValidator/BankStatementValidator'
import { generatePerfiosReport } from '../../../../components/BankStatementValidator/utils/perfiosTransaction'
import { CustomLoaderModal } from '../../../../_metronic/helpers/components/CustomLoaderModal'
import { LeftArowIcon } from '../../../../svg/LeftArowIcon'
import { useHistory } from 'react-router-dom'
import { setPartnerApplication, setPartnerSubStep } from '../redux/actions'
import { RightAeroIcon } from '../../../../svg/RightAeroIcon'
import { CheckCircleIcon } from '../../../../svg/CheckCircleIcon'
import { Xicon } from '../../../../svg/Xicon'
import { UploadIcon } from '../../../../svg/UploadIcon'
import FileUploadInfo from '../../../../components/BankStatementValidator/FileUploadInfo/FileUploadInfo'
import { Stack } from 'react-bootstrap'

const Step2: FC = ({
  // prevStep,
  // goToStep,
  updateApplication,
}: any) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [openCustomLoaderModal, setOpenCustomLoaderModal] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [openVerification, setOpenVerification] = useState(false)
  const [fileGroup, setFileGroup] = useState<any>([])
  const [uploadedFiles, setUploadedFiles] = useState<any>([])
  const partnerDetails = useSelector((state: any) => state.partnerConnect.partner)
  const [isBankReportGenerated, setIsBankReportGenerated] = useState<boolean>(false)

  const [removeAllUploadedStatements, setRemoveAllUploadedStatements] = useState<boolean>(false)

  const [uploadedCorrectStatements, setUploadedCorrectStatements] = useState(false)

  const [createIncomeDocument, onSuccessCreateIncomeDocument] = useMutation(
    mutations.CREATE_INCOME_DOCUMENTS
  )
  const [deleteIncomeDocument, onSuccessDeleteIncomeDocument] = useMutation(
    mutations.DELETE_INCOME_DOCUMENT
  )
  const [validateStmtsNow, setValidateStmtsNow] = useState<boolean>(true)

  const loanApplication = useSelector((state: any) => state.partnerConnect.partnerApplication)

  useEffect(() => {
    if (loanApplication?.incomeDocuments) {
      const { incomeDocuments } = loanApplication
      let file = []
      for (const document of incomeDocuments) {
        const _file = {
          id: document.id,
          type: document.type,
          file: document.documentFile,
        }
        file.push(_file)
      }

      const bankStatements = file.filter(({ type }: any) => type === 'bank-statement')
      if (
        bankStatements &&
        bankStatements.length &&
        loanApplication?.bankStatementsReportFileName &&
        loanApplication?.bankStatementsReportFilePath
      ) {
        setIsBankReportGenerated(true)
      }

      setValidateStmtsNow(loanApplication?.perfiosIntegration)
      setFileGroup(file)
    }
  }, [loanApplication, loanApplication?.incomeDocuments])

  useEffect(() => {
    if (removeAllUploadedStatements) {
      handleRemoveAllIncomeDocuments()
    }
  }, [removeAllUploadedStatements])

  useEffect(() => {
    if (onSuccessCreateIncomeDocument.called && !onSuccessCreateIncomeDocument.loading) {
      dispatch(fetchStop())
      if (onSuccessCreateIncomeDocument.data) {
        if (onSuccessCreateIncomeDocument.data) {
          const { createIncomeDocuments } = onSuccessCreateIncomeDocument.data
          let file = [...fileGroup]
          for (const document of createIncomeDocuments) {
            const _file = {
              id: document.id,
              type: document.type,
              file: document.documentFile,
            }
            file.push(_file)
          }

          setFileGroup(file)

          if (loanApplication.applicationCompleteStep < 2) {
            updateApplication({
              variables: {
                data: { applicationCompleteStep: 2 },
                where: { id: loanApplication.id },
              },
            })
          }

          onSuccessCreateIncomeDocument.reset()
        }
      } else {
      }

      if (onSuccessCreateIncomeDocument.error) {
        const { networkError } = onSuccessCreateIncomeDocument.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
      }
    }
  }, [
    onSuccessCreateIncomeDocument.loading,
    onSuccessCreateIncomeDocument.data,
    onSuccessCreateIncomeDocument.error,
    onSuccessCreateIncomeDocument.called,
  ])

  function backLink() {
    dispatch(setPartnerSubStep(3))
    history.replace(`/partnerconnect/${partnerDetails?.id}/${loanApplication.id}?step=1.3`)
  }

  const handleStatementUpload = async (type: string = '') => {
    try {
      let files = []
      let uploadDocs = []

      for (let file of uploadedFiles) {
        const { response, ...restFileData } = file
        file.filename = file.name
        files.push({ type: type, restFileData })
        uploadDocs.push({
          application: {
            connect: {
              id: loanApplication.id,
            },
          },
          type: type,
          documentFile: {
            upload: file,
          },
        })
      }

      // _fileGroup = _fileGroup.concat(files);
      // setFileGroup(_fileGroup);

      return createIncomeDocument({
        variables: {
          data: uploadDocs,
        },
      }).then((response: any) => {
        const { createIncomeDocuments } = response?.data

        return createIncomeDocuments
      })
    } catch (error) {
      throw error
    }
  }

  const isValidFile = (event: any, type: string) => {
    const checkType = [...event.target.files].some((file: any) => file.type !== type)
    if (checkType) {
      setShowAlert(true)
      setAlertMsg(`Please upload a ${type === 'application/pdf' ? 'pdf' : 'xlsx'} file`)
      setAlertType('primary')
      return false
    }
    return true
  }

  const handleFileUpload = (event: any, type: string, fileType: string = 'application/pdf') => {
    if (event.target.validity.valid) {
      // let _fileGroup = [...fileGroup];
      let files = []
      let uploadDocs = []

      // _fileGroup = _fileGroup.filter((o:any) => {return o.type !== type});

      const isValid = isValidFile(event, fileType)

      if (isValid) {
        for (let file of event.target.files) {
          file.filename = file.name
          files.push({ type: type, file })
          uploadDocs.push({
            application: {
              connect: {
                id: loanApplication.id,
              },
            },
            type: type,
            documentFile: {
              upload: file,
            },
          })
        }

        // _fileGroup = _fileGroup.concat(files);
        // setFileGroup(_fileGroup);
        if (files?.length) {
          event.target.value = ''
          createIncomeDocument({
            variables: {
              data: uploadDocs,
            },
          })
        }

        dispatch(fetchStart())
      }
    }
  }

  const handleRemoveAllIncomeDocuments = () => {
    try {
      if (fileGroup && fileGroup?.length) {
        for (const file of fileGroup) {
          deleteIncomeDocument({
            variables: {
              where: { id: file.id },
            },
          })
        }
        updateApplication({
          variables: {
            data: {
              applicationCompleteStep: 2,
              bankStatementsReportFileName: '',
              bankStatementsReportFilePath: '',
              bankStatementsXlsxReportFileName: '',
              bankStatementsXlsxReportFilePath: '',
            },
            where: { id: loanApplication.id },
          },
        })
        setFileGroup([])
      }
    } catch (error) {
      throw error
    }
  }

  /**
   * Calls generate report api
   * @returns report details uploaded on azure
   */
  const retrieveBankReport = async () => {
    try {
      const report = await generatePerfiosReport(
        loanApplication?.id,
        `${loanApplication?.id}_report`,
        loanApplication?.applicationType
      )
      return report
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  const removeSingleFile = (id: string) => {
    const _fileGroup = fileGroup.filter((o: any) => o.id !== id)
    setFileGroup(_fileGroup)
    deleteIncomeDocument({
      variables: {
        where: { id: id },
      },
    })
  }

  const submitAll = async () => {
    try {
      // dispatch(fetchStart())

      if (
        validateStmtsNow &&
        (loanApplication.applicationCompleteStep !== 2 ||
          !loanApplication.bankStatementsReportFilePath ||
          removeAllUploadedStatements)
      ) {
        setOpenCustomLoaderModal(true)
        const report = await retrieveBankReport()
        const incomeDocs: any = await handleStatementUpload('bank-statement')
        // await addBankReportToApplication(reportPath)

        updateApplication({
          variables: {
            data: {
              applicationCompleteStep: 2,
              bankStatementsReportFileName: report?.reportFileName,
              bankStatementsReportFilePath: report?.reportFilePath,
              bankStatementsXlsxReportFileName: report?.reportXlsxFileName,
              bankStatementsXlsxReportFilePath: report?.reportXlsxFilePath,
            },
            where: { id: loanApplication.id },
          },
        })

        const applications = {
          ...loanApplication,
          incomeDocuments: [...loanApplication.incomeDocuments, ...incomeDocs],
          applicationCompleteStep: 2,
          bankStatementsReportFileName: report?.reportFileName,
          bankStatementsReportFilePath: report?.reportFilePath,
          bankStatementsXlsxReportFileName: report?.reportXlsxFileName,
          bankStatementsXlsxReportFilePath: report?.reportXlsxFilePath,
        }
        dispatch(setPartnerApplication(applications))
        setUploadedFiles([])
        setIsBankReportGenerated(true)

        setOpenCustomLoaderModal(false)
      }

      setOpenVerification(true)
      // dispatch(fetchStop())
    } catch (error) {
      setOpenCustomLoaderModal(false)
      setShowAlert(true)
      setAlertMsg(
        'Sorry we failed to process your statements at this moment, please try after some time.'
      )
      setAlertType('primary')
    }
  }

  return (
    <div className='d-flex flex-column flex-column-fluid'>
      <div className='cardNew'>
        <div className='section-heading'>
          <h2>
            Bank Statements {uploadedCorrectStatements && <CheckCircleIcon color='var(--sucess)' />}
            {validateStmtsNow &&
              loanApplication.applicationCompleteStep === 2 &&
              loanApplication.bankStatementsReportFilePath &&
              fileGroup.length &&
              fileGroup.some(({ type }: any) => type === 'bank-statement') &&
              !removeAllUploadedStatements &&
              !uploadedCorrectStatements && <CheckCircleIcon color='var(--warning)' />}
          </h2>
          <hr className='text-gray-400' />
        </div>
        {validateStmtsNow && (
          <div className='card-body'>
            <BankStatementValidator
              refId={loanApplication?.id}
              refType={loanApplication?.applicationType}
              setUploadedCorrectStatements={setUploadedCorrectStatements}
              setUploadedFiles={setUploadedFiles}
              setIsBankReportGenerated={setIsBankReportGenerated}
              isBankReportGenerated={isBankReportGenerated}
              removeAllUploadedStatements={setRemoveAllUploadedStatements}
              validateMonthsTill={6}
            />
          </div>
        )}

        {/* if not validated by perfios */}
        {!validateStmtsNow && (
          <>
            <Stack direction='vertical' style={{ width: '100%', fontSize: '14px' }}>
              <div className='bank-statement-upload mb-3 rounded' style={{ width: '100%' }}>
                <FileUploadInfo />
              </div>
            </Stack>
            <div
              style={{
                border: '1px dashed #BBBFC4',
                padding: '63px 40px',
                background: 'rgba(26,3,85,0.02)',
                minHeight: '150px',
                fontSize: '14px',
                marginBottom: '15px',
                borderRadius: '10px',
                alignItems: 'center',
              }}
            >
              <Stack
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                gap={2}
              >
                <div className='upload mb-4'>
                  <input
                    id='upload-file-bank'
                    type='file'
                    multiple
                    accept='application/pdf'
                    onChange={(e: any) => handleFileUpload(e, 'bank-statement')}
                  ></input>
                  <label
                    htmlFor='upload-file-bank'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingTop: '10px',
                    }}
                  >
                    <div
                      className='file-uploader'
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <UploadIcon color='var(--primary-color)' />
                      <span>Drag & Drop Bank Statements</span>
                    </div>
                    <div className='file-uploader-sub'>Supported format: PDF Only</div>
                  </label>
                </div>
              </Stack>
            </div>
            <div className=''>
              <Stack
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(6, 1fr)',
                  gap: '12px',
                  fontSize: '12px',
                  minWidth: '100%',
                }}
              >
                {fileGroup && fileGroup.length > 0 && (
                  <>
                    {fileGroup
                      .filter(({ type }: any) => type === 'bank-statement')
                      .map(({ file, type, id }: any, i: number) => {
                        const filename = file.filename.split('.')
                        const name = filename[0].substring(0, 15)
                        const extension = filename[1]

                        return (
                          <div
                            key={i}
                            style={{
                              fontSize: 14,
                              color: '#616B75',
                              background: '#F9FAFA',
                              borderRadius: 4,
                              padding: '8px 16px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                            }}
                            title={file.filename}
                          >
                            <span>{`${name}...${extension}`}</span>
                            <Xicon
                              onClick={() => removeSingleFile(id)}
                              className='cursor-pointer'
                            />
                          </div>
                        )
                      })}
                  </>
                )}
              </Stack>
            </div>
          </>
        )}

        {uploadedCorrectStatements ? (
          <div className='text-success'>Your Bank Statements have been successfully validated</div>
        ) : null}

        {validateStmtsNow &&
        loanApplication.applicationCompleteStep === 2 &&
        loanApplication.bankStatementsReportFilePath &&
        fileGroup.length &&
        fileGroup.some(({ type }: any) => type === 'bank-statement') &&
        !removeAllUploadedStatements &&
        !uploadedCorrectStatements ? (
          <div className='text-alert'>
            You have already uploaded your bank statements, now you can either re-upload your bank
            statements or proceed further.
          </div>
        ) : null}
      </div>

      <div className='cardNew'>
        <div className='d-flex justify-content-between'>
          <button className='button button-outline-back' onClick={backLink}>
            <span className='icon-text'>
              <LeftArowIcon color='currentColor' />
              Back
            </span>
          </button>
          <button
            className='button button-primary'
            // disabled={fileGroup.length < 3}
            // disabled={!fileGroup.some(({type}: any) => type === 'bank-statement')}
            style={{
              background:
                (validateStmtsNow && !isBankReportGenerated && !uploadedCorrectStatements) ||
                (!validateStmtsNow && !fileGroup.some(({ type }: any) => type === 'bank-statement'))
                  ? 'grey'
                  : 'var(--secondary-color)',
            }}
            disabled={
              validateStmtsNow
                ? !isBankReportGenerated && !uploadedCorrectStatements
                : !fileGroup.some(({ type }: any) => type === 'bank-statement')
            }
            onClick={() => submitAll()}
          >
            Save and Continue <RightAeroIcon color='currentColor' />
          </button>
        </div>
      </div>

      {openVerification && (
        <CompanyVerification
          show={openVerification}
          handleClose={() => setOpenVerification(false)}
          // goToStep={goToStep}
        />
      )}

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}

      <CustomLoaderModal
        openCustomLoaderModal={openCustomLoaderModal}
        setOpenCustomLoaderModal={setOpenCustomLoaderModal}
        message='Please wait while we process your statements, it might take a while.'
      />
    </div>
  )
}

export { Step2 }
