/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { useMutation, useLazyQuery, ServerError } from '@apollo/client'

import Alert from '../../../_metronic/partials/alert'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { usePrevious } from '../../../_metronic/helpers/customHooks'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import query from '../../../setup/graphql/query'
import mutations from '../../../setup/graphql/mutations'
import { fetchStart, fetchStop } from '../../../redux/actions/common'
import {
  setPartnerApplication,
  setPartnerEligibility,
  setPartnerStep,
  setPartnerSubStep,
  setGuarantor,
  setDecisionMaker,
  setSingPassInfo,
  setDirector,
  setPartnerDetails,
} from './redux/actions'

import { Step1, Step2, Step3, Step3Sub1, Step4 } from './steps'
import ReactPixel from 'react-facebook-pixel'
import axios from 'axios'
import { Spinner } from 'react-bootstrap-v5'
import dayjs from 'dayjs'
import { getIntegrationURLFromENV, handleSettingEnv } from '../../../utils/getIntegrationURLFromENV'
import { AlexiRoutes } from '../../../utils/constants/AlexiRoutes.enum'
import { CheckCircleIcon } from '../../../svg/CheckCircleIcon'
import './SgVendorApplication.scss'
import { Step5Icon } from '../../../svg/step5'
import { Step1Icon } from '../../../svg/step1'
import { Step2Icon } from '../../../svg/step2'
import { Step3Icon } from '../../../svg/step3'
import { Step4Icon } from '../../../svg/step4'

export const dateFormatter = (date: string | Date) => {
  return dayjs(new Date(date)).format('DD MMM YYYY')
}

const PartnerConnect: FC = () => {
  const dispatch = useDispatch()
  const params: any = useParams()
  const history: any = useHistory()
  const { pathname } = useLocation()

  // let mainStep = 0, mainSubStep = 0;

  const { id, partnerId } = params
  const searchQuery: any = parse(history.location.search)

  // if(searchQuery.length>0) {
  //   mainStep = Number(searchQuery.step.split('.')[0]);
  //   mainSubStep = Number(searchQuery.step.split('.')[1]);
  // }

  const prevStep: any = usePrevious(searchQuery)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [progress, setProgress] = useState<any>(0)

  const [getApplication, onSuccessGetApplication] = useLazyQuery(query.GET_APPLICATION)
  const [updateApplication, onSuccessUpdateApplicaton] = useMutation(mutations.UPDATE_APPLICATION)
  const loanApplication = useSelector((state: any) => state.sgVendorsApplication.partnerApplication)
  const loanStep = useSelector((state: any) => state.sgVendorsApplication.partnerStep)
  const loanSubStep = useSelector((state: any) => state.sgVendorsApplication.partnerSubStep)
  const status = useSelector((state: any) => state.sgVendorsApplication.partnerApplicationStatus)
  const singpassInfo = useSelector((state: any) => state.sgVendorsApplication.singpass)
  const entityPerson = useSelector((state: any) => state.sgVendorsApplication.entityPerson)
  const forwardWithButton = useSelector((state: any) => state.common.forwardWithButton)
  const partnerDetails = useSelector((state: any) => state.sgVendorsApplication.partner)
  const partnerIdStored = localStorage.getItem('partnerId')

  const companyVerificationMethod = useSelector(
    (state: any) => state.coApplicant.companyVerificationMethod
  )
  const [loading, setLoading] = useState(false)
  const pageRedirect = (loanApplication: any) => {
    if (history.location.search.length > 0) {
      const { applicationCompleteStep } = loanApplication
      const searchQuery: any = parse(history.location.search)
      const mainStep = Number(searchQuery.step.split('.')[0])
      const mainSubStep = Number(searchQuery.step.split('.')[1])

      if (applicationCompleteStep === 5) {
        if (mainStep !== applicationCompleteStep) {
          dispatch(setPartnerStep(mainStep))
        } else {
          if (entityPerson.entity !== null || singpassInfo !== null) {
            dispatch(setPartnerStep(applicationCompleteStep))
          } else {
            dispatch(setPartnerStep(2))
            history.replace(
              `/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id || partnerIdStored}/${loanApplication.id
              }?step=2`
            )
          }
        }
      } else if (applicationCompleteStep === 4) {
        if (entityPerson.entity !== null || singpassInfo !== null) {
          dispatch(setPartnerStep(mainStep))
        } else {
          dispatch(setPartnerStep(2))
          history.replace(
            `/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id || partnerIdStored}/${loanApplication.id
            }?step=2`
          )
        }
      } else if (applicationCompleteStep === 3) {
        if (mainStep !== applicationCompleteStep) {
          dispatch(setPartnerStep(mainStep))
        } else {
          if (entityPerson.entity !== null) {
            dispatch(setPartnerStep(applicationCompleteStep))
          } else {
            dispatch(setPartnerStep(2))
            history.replace(
              `/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id || partnerIdStored}/${loanApplication.id
              }?step=2`
            )
          }
        }
      } else if (applicationCompleteStep === 2) {
        if (mainStep === applicationCompleteStep) {
          dispatch(setPartnerStep(2))
        } else {
          if (mainStep === 3 && companyVerificationMethod === 1 && entityPerson.entity == null) {
            history.push(
              `/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id || partnerIdStored}/${loanApplication.id
              }?step=2`
            )
          } else {
            dispatch(setPartnerStep(mainStep))
            dispatch(setPartnerSubStep(mainSubStep))
            history.push(
              `/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id || partnerIdStored}/${loanApplication.id
              }?step=${mainStep}${mainSubStep > 0 ? `.${mainSubStep}` : ''}`
            )
          }
          /*           if(entityPerson.entity !== null){
            dispatch(setPartnerStep(mainStep));
            dispatch(setPartnerSubStep(mainSubStep));
            history.push(`/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id || partnerIdStored}/${loanApplication.id}?step=${mainStep}${mainSubStep>0 ? `.${mainSubStep}` : ''}`);
          } else {
            dispatch(setPartnerStep(2));
            history.replace(`/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id || partnerIdStored}/${loanApplication.id}?step=2`);
          } */
        }
      } else if (applicationCompleteStep < 2) {
        if (mainStep < applicationCompleteStep) {
          dispatch(setPartnerStep(mainStep))
          dispatch(setPartnerSubStep(mainSubStep))
        } else {
          // const currentStep = loanApplication.applicationCompleteStep;
          // const step = Math.floor(currentStep) === 1 ? Math.floor(currentStep) : Math.floor(currentStep)+1;
          // const subStep = Number(currentStep.toString().split('.')[1]);

          // dispatch(setPartnerStep(step));
          // dispatch(setPartnerSubStep(subStep));
          // history.replace(`/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id || partnerIdStored}/${loanApplication.id}?step=${step}.${subStep}`);
          dispatch(setPartnerStep(mainStep))
        }
      } else {
        dispatch(setPartnerStep(mainStep))
        dispatch(setPartnerSubStep(mainSubStep))
      }
    } else {
      dispatch(setPartnerStep(1))
      dispatch(setPartnerSubStep(2))
      history.replace(
        `/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id || partnerIdStored}/${loanApplication.id
        }`
      )
    }
  }

  const getPartnerDetails = async (partnerId: string) => {
    setLoading(true)
    const env = localStorage.getItem('env')
    const API_URL = getIntegrationURLFromENV(searchQuery?.env || env)

    const partnerData = await axios.get(`${API_URL}ext/api/businesspartner/${partnerId || ''}`)

    if (partnerData.data && partnerData.status === 200) {
      localStorage.setItem('partnerId', partnerData.data?.id)
      localStorage.setItem('partnerDetails', JSON.stringify(partnerData.data))
      dispatch(setPartnerDetails(partnerData.data))

      if (!partnerId) history.push(`${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerData?.data?.id}`)

      setLoading(false)
    }
  }
  useEffect(() => {
    if (searchQuery.step !== '2') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [pathname, searchQuery])

  useEffect(() => {
    if (prevStep && Object.keys(prevStep).length > 0 && Object.keys(searchQuery).length > 0) {
      if (prevStep.step !== searchQuery.step && loanApplication && !forwardWithButton) {
        pageRedirect(loanApplication)
      }
    }

    if (Object.keys(searchQuery).length === 0 && id && status === 1) {
      dispatch(setPartnerStep(1))
      dispatch(setPartnerSubStep(2))
    }
  }, [searchQuery])

  useEffect(() => {
    const loanApplicationId = localStorage.getItem('loanApplicationId')
    /* if(id) {
      if(loanApplication === null && (loanSubStep > 2 || entityPerson) ) {
      // if(loanApplication === null) {
        getApplication({
          variables: {
            appId: id || loanApplicationId
          }
        })
      } else {
        if(loanSubStep === 0){
          history.replace('/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id || partnerIdStored}/type');

        }
      }
    } else if(status > 0) {
      const loanSubStep = status === 1 ? 1 : 0;
      dispatch(setPartnerStep(1));
      dispatch(setPartnerSubStep(loanSubStep));
    } else {
      history.replace('/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id || partnerIdStored}/type');
    } */

    if (id) {
      if (loanApplication === null) {
        getApplication({
          variables: {
            appId: id || loanApplicationId,
          },
        })
      }
    } else {
      const loanSubStep = status === 1 ? 1 : 0
      dispatch(setPartnerStep(1))
      dispatch(setPartnerSubStep(loanSubStep))
    }
  }, [id])

  useEffect(() => {
    if (partnerDetails == null) {
      getPartnerDetails(partnerId)
    }
  }, [partnerId])

  useEffect(() => {
    if (loanStep > 0) {
      const progress = progressPercentageCalculate()
      setProgress(progress)
    }
  }, [loanStep, loanSubStep])

  useEffect(() => {
    if (onSuccessGetApplication.called && !onSuccessGetApplication.loading) {
      dispatch(fetchStop())
      if (onSuccessGetApplication.data) {
        const loanApplication = onSuccessGetApplication.data.getLatestApplication
        // const currentStep = loanApplication.applicationCompleteStep;
        // const step = Math.floor(currentStep) === 1 ? Math.floor(currentStep) : Math.floor(currentStep)+1;
        // const step = currentStep < 1.4 ? Math.floor(currentStep) : Math.floor(currentStep)+1;

        if (loanApplication !== null) {
          // const loanEligibility = loanApplication.loanEligibility.length > 0 ? loanApplication.loanEligibility[0] : null;
          const {
            applicationCompleteStep,
            loanEligibility,
            shareholderKycStatus,
            externalDataStore,
          } = loanApplication
          const singpassInfo = externalDataStore.filter((o: any) => o.type === 'singpass')

          if (loanEligibility !== null) {
            dispatch(setPartnerEligibility(loanEligibility))
          }

          if (shareholderKycStatus.length > 0) {
            const decisionMaker = shareholderKycStatus.filter((o: any) => o.isDecisionMaker)[0]
            const gurantors = shareholderKycStatus.filter((o: any) => o.isGuarantor)
            const directors = shareholderKycStatus.filter((o: any) => o.isDirector)

            dispatch(setGuarantor(gurantors))
            dispatch(setDirector(directors))
            dispatch(setDecisionMaker(decisionMaker ? decisionMaker : null))
          }

          if (singpassInfo.length > 0) {
            dispatch(setSingPassInfo(singpassInfo[0].data))
          }

          pageRedirect(loanApplication)

          /* if(history.location.search.length>0){
            const searchQuery:any = parse(history.location.search)
            const mainStep = Number(searchQuery.step.split('.')[0]);
            const mainSubStep = Number(searchQuery.step.split('.')[1]);

            if(applicationCompleteStep === 4) {

            } else if(applicationCompleteStep === 3) {

            } else if(applicationCompleteStep === 2) {
              if(mainStep === applicationCompleteStep) {
                dispatch(setPartnerStep(2));
              } else {
                if(entityPerson){
                  dispatch(setPartnerStep(mainStep));
                } else {
                  dispatch(setPartnerStep(2));
                  history.replace(`/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id || partnerIdStored}/${loanApplication.id}?step=2`);
                }
              }

            } else if(applicationCompleteStep < 2) {
              if(mainStep < applicationCompleteStep) {
                dispatch(setPartnerStep(mainStep));
                dispatch(setPartnerSubStep(mainSubStep));
              } else {
                const currentStep = loanApplication.applicationCompleteStep;
                const step = Math.floor(currentStep) === 1 ? Math.floor(currentStep) : Math.floor(currentStep)+1;
                const subStep = Number(currentStep.toString().split('.')[1]);

                dispatch(setPartnerStep(step));
                dispatch(setPartnerSubStep(subStep));
                history.replace(`/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id || partnerIdStored}/${loanApplication.id}?step=${step}.${subStep}`);
              }
            } else {
              dispatch(setPartnerStep(mainStep));
              dispatch(setPartnerSubStep(mainSubStep));
            }

          } else {
            dispatch(setPartnerStep(1));
            dispatch(setPartnerSubStep(2));
            history.replace(`/${AlexiRoutes.SG_VENDORS_ONBOARDING}/${partnerDetails?.id || partnerIdStored}/${loanApplication.id || loanApplicationId}`);
          } */

          /* const currentStep = loanApplication.applicationCompleteStep;
          const currentSubStep = Number(currentStep.toString().split('.')[1]);

          const step = currentStep < 1.4 
            ? Math.floor(currentStep) 
            : entityPerson !== null ? 3 : Math.floor(currentStep)+1;
          const subStep = currentSubStep === 1 ? 3 : currentSubStep+1; */
          dispatch(setPartnerApplication(loanApplication))
        } else {
          if (status === 1) {
            history.replace(
              `/${AlexiRoutes.SG_VENDORS_ONBOARDING}/type?partner=${partnerDetails?.id || partnerIdStored
              }`
            )
          }
        }
      } else {
      }

      if (onSuccessGetApplication.error) {
        setShowAlert(true)
        setAlertMsg('error')
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [onSuccessGetApplication.loading])

  useEffect(() => {
    if (onSuccessUpdateApplicaton.called && !onSuccessUpdateApplicaton.loading) {
      // dispatch(fetchStop());
      if (onSuccessUpdateApplicaton.data) {
        const { updateApplication } = onSuccessUpdateApplicaton.data
        const application = { ...loanApplication, ...updateApplication }
        dispatch(setPartnerApplication(application))
        onSuccessUpdateApplicaton.reset()
        dispatch(fetchStop())
      } else {
      }

      if (onSuccessUpdateApplicaton.error) {
        const { networkError } = onSuccessUpdateApplicaton.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
      }
    }
  }, [
    onSuccessUpdateApplicaton.loading,
    onSuccessUpdateApplicaton.data,
    onSuccessUpdateApplicaton.error,
    onSuccessUpdateApplicaton.called,
  ])

  const additionalProps: any = {
    updateApplication: updateApplication,
    getApplication: getApplication,
  }
  /* const progressPercentageCalculate = (currentStep: number) => {
    let currentStepIndex = currentStep - 1
    if(currentStep >= 2) return currentStepIndex / 5 * 100
    if (currentStep >= 1 &&  currentStep <  2) return parseFloat((20 * (((currentStep * 10 % 10) - 1) / 3)).toFixed(2))
    return 0
  } */

  const progressPercentageCalculate = () => {
    let progress = 0
    if (loanStep > 0 || loanSubStep > 0) {
      if (loanStep === 1) {
        progress = Math.floor(5 * loanSubStep)
      } else if (loanStep > 1) {
        progress = Math.floor(20 * loanStep)
      }
    }

    return progress
  }

  useEffect(() => {
    ReactPixel.track('Applicaiton Registration Start')

    document.title = 'B2B PayNow'

    handleSettingEnv(searchQuery?.env)

    window.addEventListener('beforeunload', saveStateToLocalStorage)
    const storeDate = JSON.stringify(localStorage.getItem('storeDate'))
    const dateDiff = getDifferenceInMinutes(new Date(storeDate), new Date())
    if (dateDiff < 1) {
      const partnerData = JSON.parse(localStorage.getItem('partnerDetails') as string)
      dispatch(setPartnerDetails(partnerData))
    } else {
      localStorage.removeItem('partnerDetails')
    }
  }, [])

  const saveStateToLocalStorage = () => {
    if (partnerDetails) {
      localStorage.setItem('partnerDetails', JSON.stringify(partnerDetails))
      localStorage.setItem('storeDate', new Date().toString())
    }
  }

  const getDifferenceInMinutes = (date1: any, date2: any) => {
    const diffInMs = Math.abs(date2 - date1)
    return diffInMs / (1000 * 60)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 142) {
        document.body.classList.add('fixed')
      } else if (window.scrollY < 105) {
        document.body.classList.remove('fixed')
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      {loading ? (
        <div
          style={{ height: '100vh' }}
          className='d-flex justify-content-center align-items-center'
        >
          <Spinner
            style={{
              height: '5.35rem',
              width: '5.35rem',
            }}
            animation='grow'
            variant='danger'
          />
        </div>
      ) : (
        <div className='container sgVendorApplication'>
          <div className={loanSubStep !== 0 || loanStep > 1 ? 'cardNew stepperNewWrap' : 'cardNew'}>
            {loanSubStep === 0 && loanStep === 1 && (
              <div className='resumeApplication'>
                <CheckCircleIcon fontSize={32} color='var(--primary-color)' />
                <div>
                  <h3 className='section-heading'>Resume Application</h3>
                  <p>
                    Welcome back, you are hardly 2 steps away, please continue the journey and
                    complete the application.
                  </p>
                </div>
              </div>
            )}
            {(loanSubStep !== 0 || loanStep > 1) && (
              <ul className='stepperNew'>
                <li className={loanStep === 1 ? 'current' : loanStep > 1 ? 'completed' : ''}>
                  <div className='stepBox'>
                    <div className='iconWrap'>
                      {loanStep > 1 ? (
                        <CheckCircleIcon fontSize={24} color='var(--text-color-success)' />
                      ) : (
                        <Step1Icon
                          color={
                            loanStep === 1
                              ? 'var(--text-color-primary)'
                              : 'var(--border-color-grey1)'
                          }
                        />
                      )}
                    </div>
                    <div className='right'>
                      <span>STEP 1</span>
                      <p className='truncate-text' data-tooltip='Credit Line Details'>
                        Credit Line Details
                      </p>
                    </div>
                  </div>
                </li>
                <li className={loanStep === 2 ? 'current' : loanStep > 2 ? 'completed' : ''}>
                  <div className='stepBox'>
                    <div className='iconWrap'>
                      {loanStep > 2 ? (
                        <CheckCircleIcon fontSize={24} color='var(--text-color-success)' />
                      ) : (
                        <Step2Icon
                          color={
                            loanStep === 2
                              ? 'var(--text-color-primary)'
                              : 'var(--border-color-grey1)'
                          }
                        />
                      )}
                    </div>
                    <div className='right'>
                      <span>STEP 2</span>
                      <p className='truncate-text' data-tooltip='Income Documents'>
                        Income Documents
                      </p>
                    </div>
                  </div>
                </li>
                <li className={loanStep === 3 ? 'current' : loanStep > 3 ? 'completed' : ''}>
                  <div className='stepBox'>
                    <div className='iconWrap'>
                      {loanStep > 3 ? (
                        <CheckCircleIcon fontSize={24} color='var(--text-color-success)' />
                      ) : (
                        <Step3Icon
                          color={
                            loanStep === 3
                              ? 'var(--text-color-primary)'
                              : 'var(--border-color-grey1)'
                          }
                        />
                      )}
                    </div>
                    <div className='right'>
                      <span>STEP 3</span>
                      <p className='truncate-text' data-tooltip='Company Profile'>
                        Company Profile
                      </p>
                    </div>
                  </div>
                </li>
                <li className={loanStep === 4 ? 'current' : loanStep > 4 ? 'completed' : ''}>
                  <div className='stepBox'>
                    <div className='iconWrap'>
                      {loanStep > 4 ? (
                        <CheckCircleIcon fontSize={24} color='var(--text-color-success)' />
                      ) : (
                        <Step4Icon
                          color={
                            loanStep === 4
                              ? 'var(--text-color-primary)'
                              : 'var(--border-color-grey1)'
                          }
                        />
                      )}
                    </div>
                    <div className='right'>
                      <span>STEP 4</span>
                      <p className='truncate-text' data-tooltip='Shareholders'>
                        Shareholders
                      </p>
                    </div>
                  </div>
                </li>
                <li className={loanStep === 5 ? 'current' : loanStep > 5 ? 'completed' : ''}>
                  <div className='stepBox'>
                    <div className='iconWrap'>
                      {loanStep > 5 ? (
                        <CheckCircleIcon fontSize={24} color='var(--text-color-success)' />
                      ) : (
                        <Step5Icon
                          color={
                            loanStep === 5
                              ? 'var(--text-color-primary)'
                              : 'var(--border-color-grey1)'
                          }
                        />
                      )}
                    </div>
                    <div className='right'>
                      <span>STEP 5</span>
                      <p className='truncate-text' data-tooltip='Review Application'>
                        Review Application
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            )}
          </div>

          <div className={loanStep === 1 ? 'current' : ''} data-kt-stepper-element='content'>
            {loanStep === 1 && <Step1 {...additionalProps} />}
          </div>

          <div className={loanStep === 2 ? 'current' : ''} data-kt-stepper-element='content'>
            {loanStep === 2 && <Step2 {...additionalProps} />}
          </div>

          <div className={loanStep === 3 ? 'current' : ''} data-kt-stepper-element='content'>
            {loanStep === 3 && <Step3 {...additionalProps} />}
          </div>

          <div className={loanStep === 4 ? 'current' : ''} data-kt-stepper-element='content'>
            {loanStep === 4 && <Step3Sub1 {...additionalProps} />}
          </div>

          <div className={loanStep === 5 ? 'current' : ''} data-kt-stepper-element='content'>
            {loanStep === 5 && <Step4 {...additionalProps} />}
          </div>

          {showAlert && (
            <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
              {alertMsg}
            </Alert>
          )}
        </div>
      )}
    </>
  )
}

export default PartnerConnect
